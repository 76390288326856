<template>
  <div class="friendsCircle_imageText_page">
    <div class="header_common_box">
      <div class="headerPage">
        <van-nav-bar :title="title" left-arrow :border="false">
          <template #left>
            <van-icon name="arrow-left" size="20" @click="goBack" />
          </template>
          <template #right>
            <span style="color: red;" @click="onSubmit">发表</span>
          </template>
        </van-nav-bar>
      </div>

      <!-- 照片上传 -->
      <p class="tips" v-if="approveType === '3'">赠人玫瑰，手留余香更幸运</p>
      <div v-if="approveType === '4'" class="textarea">
        <van-field v-model="message" rows="5" autosize type="textarea" placeholder="淫秽、欺诈、广告（包括图片水印广告）、留联系方式、宗教、反动等，都将被禁言。文明发言，净化环境，一起打造最有价值的社区。" />
        <span style="color: #1989fa;font-size: 14px;">《彩友圈管理规范》</span>
      </div>

      <div class="photo_upload">
        <van-uploader v-model="fileList" :after-read="afterRead" />
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      title: '',
      approveType: '1',
      fileList: [],
      message: ''
    }
  },

  created() {
    if (this.$store.state.common.loginInfo.access_token) {
      this.approveType = this.$route.query.key
      this.title = `第${this.$route.query.periodText}${this.approveType === '3' ? '-图纸' : ''}`
    } else {
      //this.goPage('login')
      this.$router.replace('/login')
    }
  },
  methods: {
    async onSubmit() {
      if (this.approveType == '3' && !this.fileList.length) {
        this.$dialog.alert({
          title: '提示',
          message: '分享图纸图书，有机会得鸡蛋，赠人玫瑰，手留余香，让自己更幸运。点击“相机”按钮添加图纸图书吧',
        }).then(() => {
          // on close
        });
        return false
      }
      if (this.approveType == '4' && !this.message) {
        this.$dialog.alert({
          title: '提示',
          message: '您尚未填写要发表的文字内容',
        }).then(() => {
          // on close
        });
        return false
      }

      let data = {
        approveType: this.approveType, //	integer($int32) 分类 审核类型： 1免审 2带规律图纸 3只是图纸 4鸡汤
        contents: '', //		string 帖子内容
        imagesUrlArray: [],
        lotteryKind: this.$store.state.common.lotteryKind, //	integer($int32) 7:七星彩 5:排列五
        points: '', //	integer($int32)帖子需要的积分
        vipLevel: '', //		integer($int32)看此帖子的级别
      }
      // 照片
      data.imagesUrlArray = this.fileList.map(item => { return item.url })
      // 老母鸡汤
      if (this.approveType === '4') {
        // data.contents = `<p>${this.message}</p>`
        data.contents = this.message
        //老母鸡汤先不给发表 后期需改
        // Toast({
        //       message: '该功能开发中...',
        //       position: 'middle',
        //       });
        // return false
      }
      console.log(data)
      const res = await this.$HTTP.post(this, 'API_USERCENTER_FORUM_ONE', data, { headers: { access_token: this.$store.state.common.loginInfo.access_token } }, true)
      if (res.statusCode === 200) {
        this.$toast('发表成功');
        setTimeout(() => {
          this.$router.go(-2);
        }, 1000)
      }
    },
    // 上传照片
    afterRead(src) {
      // 此时可以自行将文件上传至服务器
      let file = src.file
      let param = new FormData()       // 创建form对象     
      param.append('file', file)       // 通过append向form对象添加数据
      let config = {
        headers: { 'Content-Type': 'multipart/form-data', access_token: this.$store.state.common.loginInfo.access_token }
      }
      // this.$refs.refIdInput.value = ''
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true });
      this.$axios.post(this.$API['API_FILESYSTEM_FILEINFO_FILE'] + '?fileSource=QINIU', param, config).then((res) => {
        // console.log('55555555555555555555')
        console.log(res.data.fileUrl)
        if (res?.data?.fileUrl) {
          let len = this.fileList.length - 1
          this.fileList[len].url = res.data.fileUrl
          this.$toast('上传成功');
        } else {
          this.$toast(res.data.content.resultMessage || '上传失败！');
        }
      }).catch(() => {
        console.log('44444444444444');
        this.$toast("图片上传失败，请重新上传!")
      })
    },
  }
};
</script>

<style lang="less">
.friendsCircle_imageText_page {
  .tips {
    font-size: 14px;
    text-align: center;
    margin: 16px;
  }
  .textarea {
    margin: 12px;
    border-radius: 3px;
    overflow: hidden;
  }
  .photo_upload {
    margin: 12px;
    .van-uploader__upload {
      background-color: white;
    }
  }
}
</style>
